
@import "application/views/scripts/scss/studyladder-theme";

	.large-but {
	    font-size: 40px!important;
	    padding-left: 50px!important;
	    padding-right: 50px!important;
	    padding-top: 10px!important;
	    padding-bottom: 15px!important;
	    border-radius: 10px!important;
	    font-family: 'Muli';
	    -webkit-filter: drop-shadow(2px 2px 5px rgba(0,0,0,0.2));
	    filter: drop-shadow(2px 2px 5px rgba(0,0,0,0.2));
	}
	.join-but{
		color:#fff; background-color:#347ccc;
	}

.home-hero-container {
	.text-holder{position: absolute; top:160px; right:10%;color:#fff;}
	h1{
		font-size:82px!important;line-height:66px;color:#fff;padding-top:60px;
		span {font-size:54px;}
	}
	h2 {font-size:36px!important;line-height:46px; margin-left:-60px;color:#fff;padding-top:60px;}
	h3{font-size:22px!important;line-height:32px;margin-left:-120px;color:#fff;padding-top:60px;}
	
	.p-holder{margin-left:-220px;padding-top:60px;}
	.hero-img-m {
		display:none;
	}
}

@media only screen and (max-width:2200px) {
	
	.home-hero-container  .text-holder{right:9%;top:120px;}
}
@media only screen and (max-width:2100px) {
	.home-hero-container  .text-holder{right:6%;}
	
	.home-hero-container {
		.text-holder{top:100px; }
		h1{
			font-size:72px!important;line-height:56px;padding-top:40px;
			span {font-size:44px;}
		}
		h2 {font-size:30px!important;line-height:40px; margin-left:-60px;padding-top:40px;}
		h3{font-size:20px!important;line-height:30px;margin-left:-120px;padding-top:40px;}
		.p-holder{margin-left:-200px;padding-top:40px;}
	}
	
}
@media only screen and (max-width:1800px) {
	.home-hero-container  .text-holder{right:4%;}
	.large-but {
	    font-size: 30px!important;
	    padding-left: 30px!important;
	    padding-right: 30px!important;
	    padding-top: 6px!important;
	    padding-bottom: 10px!important;
	}
}


@media only screen and (max-width:1650px) {
	.home-hero-container  .text-holder{right:10%;}
	
	.home-hero-container {
		.text-holder{top:80px; }
		h1{
			font-size:52px!important;line-height:46px;padding-top:30px;
			span {font-size:34px;}
		}
		h2 {font-size:26px!important;line-height:30px; margin-left:-50px;padding-top:40px;}
		h3{font-size:20px!important;line-height:30px;margin-left:-100px;padding-top:40px;}
		.p-holder{margin-left:-160px;padding-top:40px;}
	}
}
@media only screen and (max-width:1450px) {
	.home-hero-container  .text-holder{right:5%;top:40px;}
}

@media only screen and (max-width:1350px) {
	.home-hero-container  .text-holder{right:7%;top:70px;}
	.home-hero-container {
		h1{
			font-size:48px!important;line-height:42px;padding-top:30px;
			span {font-size:30px;}
		}
		h2 {font-size:22px!important;line-height:26px; margin-left:-40px;padding-top:26px;}
		h3{font-size:18px!important;line-height:20px;margin-left:-80px;padding-top:30px;}
		.p-holder{margin-left:-140px;padding-top:30px;}
	}
	.large-but {
	    font-size: 25px!important;
	    padding-left: 25px!important;
	    padding-right: 25px!important;
	    padding-top: 5px!important;
	    padding-bottom: 5px!important;
	}	
}
@media only screen and (max-width:1200px) {
	.home-hero-container  .text-holder{right:4%;top:40px;}
}

@media only screen and (max-width:1180px) {
	.home-hero-container  .text-holder{right:30px;top:30px;}
}
@media only screen and (max-width:1120px) {
	.home-hero-container  .text-holder{right:5%;top:40px;}
	.home-hero-container {
		h1{
			font-size:38px!important;line-height:32px;padding-top:30px;
			span {font-size:22px;}
		}
		h2 {font-size:20px!important;line-height:22px; margin-left:-40px;padding-top:16px;}
		h3{font-size:17px!important;line-height:20px;margin-left:-80px;padding-top:20px;}
		.p-holder{margin-left:-140px;padding-top:20px;}
	}
	.large-but {
	    font-size: 20px!important;
	    padding-left: 20px!important;
	    padding-right: 20px!important;
	    padding-top: 5px!important;
	    padding-bottom: 5px!important;
	}	
}
@media only screen and (max-width:940px) {
	.home-hero-container  .text-holder{right:8%;top:30px;}
	.home-hero-container {
		h1{
			font-size:32px!important;line-height:26px;padding-top:30px;
			span {font-size:20px;}
		}
		h2 {font-size:17px!important;line-height:22px; margin-left:-30px;padding-top:6px;}
		h3{font-size:15px!important;line-height:20px;margin-left:-60px;padding-top:10px;}
		.p-holder{margin-left:-110px;padding-top:10px;}
	}
	.large-but {
	    font-size: 18px!important;
	    padding-left: 20px!important;
	    padding-right: 20px!important;
	    padding-top: 5px!important;
	    padding-bottom: 6px!important;
	}	
}
@media only screen and (max-width:860px) {
	.home-hero-container  .text-holder{right:5%;top:20px;}
}

@media only screen and (max-width:800px) {
	.home-hero-container  {
		.text-holder{right:5%;top:50px;}
		h2 {display:none;}
		h3{margin-left:0px;}
		.p-holder{margin-left:0px}
		.find-but {display:none;}
	}
}
@media only screen and (max-width:730px) {
	.home-hero-container  {
		.text-holder {
			display: block;
		    width: 100%;
		    right: initial;
		}
		h1 {
			padding-top:25px;
		}
		h2 {display:inline-block;}
		h3 {
			padding-top: 20px;
		}
		.find-but {display:inline-block;}
		.p-holder{padding-top: 20px;}
		.hero-img-d {display:none;}
		.hero-img-m {display:block;}
		.col-md-12{padding-left:0px; padding-right:0px;}
	}
}





@include media-breakpoint-down(md) {

	//user segments
	.user-segments {
		.card-deck .card {
			margin-bottom: 3rem;
		}
		.card-body>p {
			margin-top: 0.75em;
		}
	}

	//activity
	.activity-summary .lead {
		line-height: 1.4;
		font-size: 1rem;
	}
	.subjects, .popular {
		h5 {
			font-size: 1.0rem!important;
		}
	}

}


@include media-breakpoint-down(sm) {
	//home
	.jumbotron {
		padding: 1rem 1rem;
	}

	//activity summery
	.activity-summary .lead {
		font-size: 1.0em;
		font-weight: 300;
		text-align: center;
		letter-spacing: 0.5px;
	}
}

@keyframes user-segment-card-fadein {
  from { opacity: .1; transform: scale(.9) translateY(-2em) }
  to { opacity: 1; transform: scale(1) translateY(0) }
}
.user-segment-card{opacity:0}
.user-segment-card-fadein{animation: .7s user-segment-card-fadein;opacity:1}

@keyframes subject-fadein {
  from { opacity: .1; transform: scale(.8) translateY(5em) }
  to { opacity: 1; transform: scale(1) translateY(0) }
}
.subject{opacity:0}
.subject-fadein{animation: .7s subject-fadein;opacity:1}
